body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.App {
  text-align: center;
}

#test {
 background-color: rgb(172, 175, 192);
}

#home {
  /* background: rgb(34, 51, 38); */
  /* background: radial-gradient(circle, rgba(55,53,80,0.8379726890756303) 5%, rgba(42,41,79,0.41780462184873945) 46%, rgba(30,30,78,0) 70%); */
}

div#full-content {
  /* navbar and page */
  overflow-x: scroll;
  min-height: 100vh;
  height: 100%;
  background-color: #282c34;
}

/* #navbar-nav {
  max-width: 100%;
  overflow-y: hidden;
  color: white;
  background-color: rgb(185, 205, 241);
} */

.color-nav {
  max-width: 100%;
  overflow-y: hidden;
  /* background-color: rgb(18, 107, 55); */
  /* background-color: #abe4e6; */
  background-color: rgb(185, 220, 241);
} .color-nav a {
  /* color: white !important; */
  /* color: #282c34 !important; */
}

.App-header {
  /* background: linear-gradient(0deg, rgba(53,80,59,1) 0%, rgba(55,53,80,1) 3%, rgba(30,30,78,1) 97%, rgba(16,67,78,1) 100%); */
  /* background: linear-gradient(0deg, rgba(55,53,80,1) 0%, rgba(30,30,78,1) 100%); */
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 30px
}

div#main-content {
  justify-content: center;
}

div#page-content {
  padding: auto;
  margin: auto;
}

@keyframes rainbow_rotate {
  0%   {filter: hue-rotate(0deg);}
  5%   {filter: hue-rotate(36deg);}
  10%   {filter: hue-rotate(72deg);}
  15%   {filter: hue-rotate(108deg);}
  20%   {filter: hue-rotate(144deg);}
  25%   {filter: hue-rotate(180deg);}
  30%   {filter: hue-rotate(216deg);} 
  35%   {filter: hue-rotate(252deg);}
  40%   {filter: hue-rotate(288deg);}
  45%   {filter: hue-rotate(324deg);}
  50%   {filter: hue-rotate(360deg);}
  55%   {filter: hue-rotate(324deg);}
  60%   {filter: hue-rotate(288deg);}
  65%   {filter: hue-rotate(252deg);}
  70%   {filter: hue-rotate(216deg);} 
  75%   {filter: hue-rotate(180deg);}
  80%   {filter: hue-rotate(144deg);}
  85%   {filter: hue-rotate(108deg);}
  90%   {filter: hue-rotate(72deg);}
  95%   {filter: hue-rotate(36deg);}
  100%   {filter: hue-rotate(0deg);}
}

#squig {
  width: 100%;
  max-width: 1000px;
  max-height: 50px;
  margin: auto;
  animation-name: rainbow_rotate;
  /* filter: hue-rotate(359deg); */
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

.normlink {
  text-decoration: none;
  display: inline-block;
  padding: 8px 16px;
  background-color: rgb(167, 167, 167);
  color: black;
  border:0 none;
  cursor:pointer;
  -webkit-border-radius: 5px;
  border-radius: 5px; 
} .normlink:hover {
  text-decoration: none;
  background-color: #ddd;
  color: rgb(34, 34, 34);
}

.normlink a {
  text-decoration: none;
  color: black;
}

a.quietLink {
  text-decoration: none;
  color: black;
} a.quietLink:hover {
  text-decoration: none;
  color: rgb(30, 25, 54);
}


.download-btn {
  background-color: DodgerBlue;
  border: none;
  color: white;
  padding: 12px 30px;
  cursor: pointer;
  font-size: 20px;
} .download-btn:hover {
  background-color: RoyalBlue;
}

.card-footer {
  transition: .3s ease-out;
  -webkit-transition: 0.3s ease-out;
}

.jumbotron {
  background-color: #0808082c;
  max-width: 800px;
  color: black;
}


/* goodreads embedding; pasted style from their script on their website */
#gr_challenge {border: 2px solid #EBE8D5; background-color: white; border-radius:10px; padding: 0px 7px 0px 7px; max-width:250px; min-height: 100px}
#gr_challenge_11636 {border: 2px solid #EBE8D5; border-radius:10px; padding: 0px 7px 0px 7px; max-width:230px; min-height: 100px}
#gr_challenge_progress_body_11636 {font-size: 12px; font-family: georgia,serif;line-height: 18px}
#gr_challenge h3 {margin: 4px 0 10px; font-weight: normal; text-align: center; font-size: 15px;}
#gr_challenge a {color: #00635d; text-decoration: none;}
